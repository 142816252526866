<template>
  <div class="announcement-add">
    <div class="container-fluid py-4">
      <h2 class="h4 fw-medium mb-4">{{ $t("__announcementAdd") }}</h2>

      <div class="row">
        <!-- 左側欄：顯示所有 agency 供選擇 -->
        <div class="col-md-4 mb-3">
          <b-card :header="$t('__chooseAnAgency')">
            <b-list-group class="agency-list">
              <b-list-group-item>
                <input
                  id="all"
                  v-model="selectAllAgencies"
                  class="form-check-input me-2"
                  type="checkbox"
                  @change="toggleSelectAll"
                /><label for="all" class="mb-0 w-100 h-100 d-inline">{{ $t('__allAgency') }}</label>
              </b-list-group-item>

              <b-list-group-item
                v-for="agency in allAgencys"
                :key="agency.id"
                class="d-flex align-items-center"
              >
                <input
                  :id="'agency-' + agency.id"
                  v-model="selectedAgencies"
                  class="form-check-input me-2"
                  type="checkbox"
                  :value="agency.id"
                  @change="onChangeAgencySelection"
                  :disabled="disabledAgencies[agency.id]"
                />
                <label :for="'agency-' + agency.id" class="mb-0 w-100">
                  {{ agency.name }}
                </label>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </div>

        <!-- 右側欄：公告編輯和預覽 -->
        <div class="col-md-8">
          <div class="card mb-3">
            <div class="card-body preview">
              <div class="preview-title mb-3">
                <h2>
                  {{ $t('__announcementPreview') }}
                </h2>
              </div>
              <div class="preview-content">
                <h3>{{ form.title }}</h3>
                <p>{{ form.content }}</p>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <b-form @submit.prevent="onSubmit">
                <div class="mb-3">
                  <label for="name" class="form-label fw-medium required">{{
                      $t("__announcementTitle")
                    }}</label>
                  <input
                    v-model="form.title"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>

                <div class="mb-3">
                  <label for="announcement-status" class="form-label required">{{ $t("__announcementStatus") }}</label>
                  <select class="form-select" v-model="form.status" required>
                    <option disabled :value="null">{{ $t("__openThisSelectMenu") }}</option>
                    <option
                      v-for="item in statusOptions"
                      :key="item.value"
                      :value="item.value"
                    >
                      {{ item.text }}
                    </option>
                  </select>
                </div>

                <div class="mb-3">
                  <label for="announcement-category" class="form-label required">{{ $t("__announcementCategory") }}</label>
                  <select class="form-select" v-model="form.category_id" required>
                    <option disabled :value="null">{{ $t("__openThisSelectMenu") }}</option>
                    <option
                      v-for="category in categories"
                      :key="category.id"
                      :value="category.id"
                    >
                      {{ category.name }}
                    </option>
                  </select>
                </div>

                <div class="mb-3">
                  <label for="name" class="form-label fw-medium required">{{
                      $t("__announcementContent")
                    }}</label>
                  <textarea
                    v-model="form.content"
                    class="form-control"
                    rows="7"
                    required
                  >
                </textarea>
                </div>
                <div style="text-align: right">
                  <button
                    type="button"
                    class="btn btn-outline-secondary rounded-pill m-1"
                    @click="$router.go(-1)"
                  >
                    {{ $t("__cancel") }}
                  </button>
                  <button type="submit" class="btn btn-primary rounded-pill">
                    {{ $t("__save") }}
                  </button>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tooltip } from 'bootstrap'
import i18n from '@/lang/lang.js'
import { agency } from '@/http/api/agency.js'
import { mapState, mapMutations } from 'vuex'
import { announcement } from "@/http/api/announcement";

export default {
  name: 'AnnouncementEdit',
  data () {
    return {
      selectedAgencies: [],
      selectAllAgencies: false,
      allAgencys: null,
      bootstrap_tooltip: null,
      form: {
        title: '',
        content: '',
        status: '',
        category_id: '',
        selectedAgencies: {
          type: 'all',
          agencyIds: [],
        }
      },
      statusOptions: [
        { value: '0', text: this.$t('__announcementStatusDraft') },
        { value: '1', text: this.$t('__announcementStatusPublished') },
        { value: '2', text: this.$t('__announcementStatusCancel') },
      ],
      categories: [],
      disabledAgencies: {}
    }
  },
  computed: {
    ...mapState(['token', 'userInfo']),
    permissions () {
      return this.userInfo.permissions
    },
    isSystemManage () {
      let systemManage = false
      if (!this.permissions) return false
      this.permissions.forEach((item) => {
        if (item.name === 'system-manage') {
          systemManage = true
        }
      })
      return systemManage
    }
  },
  watch: {
  },
  methods: {
    ...mapMutations(['Loading', 'Loaded', 'AlertsInfo', 'Alerted']),
    getAnnouncement(id) {
      announcement.get(id, this.token).then(response => {
        this.selectedAgencies = response.data.data.notifications.map(n => n.agency_id);
        this.disabledAgencies = response.data.data.notifications.reduce((acc, n) => {
          acc[n.agency_id] = n.notified_at != null;
          return acc;
        }, {});

        delete response.data.data.notifications;

        this.form = { ...response.data.data };
        this.form.selectedAgencies = {
          type: 'select',
          agencyIds: []
        };

      });
    },
    getAllAgency () {
      const vm = this
      agency.get('', this.token).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.allAgencys = res.data.data
        }
      })
    },
    getCategories () {
      const vm = this
      announcement.getCategories(this.token).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.categories = res.data.data
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errMsg.toString()
          })
          vm.Alerted()
        }
      }).catch((error) => {
        vm.AlertsInfo({
          state: 'error',
          title: i18n.t('__error'),
          info: error
        })
        vm.Alerted()
      })
    },
    initBootstrapTooltip () {
      this.$nextTick(function () {
        this.bootstrap_tooltip = [].slice
          .call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
          .map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
          })
        document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(ele => {
          ele.addEventListener('shown.bs.tooltip', function () {
            setTimeout(() => {
              Tooltip.getInstance(ele).hide()
            }, 600)
          })
        })
      })
    },
    hideTooltip () {
      if (this.bootstrap_tooltip && this.bootstrap_tooltip.length) {
        this.bootstrap_tooltip.forEach((tooltip) => {
          tooltip.hide()
        })
      }
    },
    onSubmit () {
      const vm = this

      vm.form.selectedAgencies.type = 'all'
      vm.form.selectedAgencies.agencyIds = []
      vm.form.id = this.$route.params.id

      if (!vm.selectAllAgencies) {
        vm.form.selectedAgencies.type = 'select'
        vm.form.selectedAgencies.agencyIds = vm.selectedAgencies
      }

      if (vm.form.selectedAgencies.type !== 'all' && vm.form.selectedAgencies.agencyIds.length === 0) {
        vm.AlertsInfo({
          state: 'error',
          title: i18n.t('__error'),
          info: i18n.t('__announcementSelectAgency')
        })
        vm.Alerted()
        return
      }

      if (vm.form.status == 1 && !confirm(i18n.t('__announcementSendConfirm'))) {
        return
      }

      announcement.create(vm.form, vm.token)
        .then((res) => {
          if (res.status <= 201 && res.data.status === 'success') {
            vm.AlertsInfo({
              state: 'success',
              title: i18n.t('__success'),
              info: i18n.t('__createSuccess')
            })

            vm.resetForm();
            vm.$router.push({ name: 'Announcements' });

          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg.toString()
            })
          }
        })
        .catch((error) => {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: error
          })
        })

      vm.Loaded()
      vm.Alerted()
    },
    resetForm() {
      this.form = {
        title: '',
        content: '',
        status: '',
        category_id: '',
        selectedAgencies: {
          type: 'all',
          agencyIds: [],
        }
      };
      this.selectedAgencies = [];
      this.selectAllAgencies = false;
    },
    toggleSelectAll() {
      if (this.selectAllAgencies) {
        // 全選所有 agency
        this.selectedAgencies = this.allAgencys.map(agency => agency.id);
      } else {
        // 取消選擇所有 agency
        this.selectedAgencies = [];
      }
    },
    onChangeAgencySelection() {
      this.selectAllAgencies = false;
    }
  },
  created () {
    if (this.$route.params.id) {
      this.getAnnouncement(this.$route.params.id);
    }

    this.getAllAgency()
    this.getCategories()
  },
  mounted () {
  }
}
</script>

<style lang="scss">
  .agency-list {
    max-height: 700px;
    overflow-y: auto;
  }

  .preview {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .preview-title {
      text-align: center;
    }

    .preview-content {
      margin: 0 auto;
      width: 80%;
      border: 1px solid #000;
      border-radius: 15px;
      padding: 20px;
      text-align: center;
      white-space: pre;
      word-wrap: break-word;
      word-break: break-all;
    }
  }
</style>
